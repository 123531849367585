import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { AppLink2 } from '~/components/AppLink2';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { ComponentBoundary } from '~/modules/SDK/FrError/components/ComponentBoundary';
import IntradayTrendChart from '~/modules/trendChart';
import TitleSymbolQuote from '../../modules/investment-consultant/symbolList/body/SymbolQuoteTitle';
import { store } from '../heineken_template/_private/store';
import { goodwayStore } from './component/goodwayStore';
import { useDaddy960Tradeinfo } from './component/useDaddy960Tradeinfo';
const chartHight = 112;
const priceLabel = false;
const timeLabel = false;
export const Daddy960_goodway_SidePane2 = memo(function Daddy960_goodway_SidePane2() {
    const charting = useSnapshot(store.charting);
    const symbolNumber = charting.symbol;
    const aaa = useDaddy960Tradeinfo().data;
    return (<div css={css `
        ${fill_vertical_cross_center};
        padding: 4px;
        gap: 4px;
      `}>
      <styleds.symbolSwitchContent>
        <PageSwitch url='/heineken_template' keys='index'>
          960看盤
        </PageSwitch>
        <PageSwitch url='/heineken_template/goodway/monitors' keys='monitors'>
          觀察指標
        </PageSwitch>
        <PageSwitch url='/heineken_template/goodway/os-monitors' keys='os-monitors'>
          海期監控
        </PageSwitch>
      </styleds.symbolSwitchContent>
      <styleds.symbolSwitchContent>
        <styleds.symbolButton selected={charting.symbol === 'TX-1'} onClick={() => {
            store.charting.changeSymbol('TX-1');
        }}>
          TX-1
        </styleds.symbolButton>
        <styleds.symbolButton selected={charting.symbol === 'TXAM-1'} onClick={() => {
            store.charting.changeSymbol('TXAM-1');
        }}>
          TXAM-1
        </styleds.symbolButton>
      </styleds.symbolSwitchContent>
      <ComponentBoundary filepath='<TitleSymbolQuote.Default symbol={symbolNumber} />'>
        <TitleSymbolQuote.Default symbol={symbolNumber}/>
      </ComponentBoundary>
      <styleds.calueCardContainer>
        <ValueCard value={aaa.tx_bvav}>坦克</ValueCard>
        <ValueCard value={aaa.mtx_tbta} reverse={true}>
          炮灰
        </ValueCard>
        <ValueCard value={aaa.mtx_bvav}>游擊隊</ValueCard>
      </styleds.calueCardContainer>
      <ComponentBoundary filepath='<Line960StateCard />'>
        <Line960StateCard />
      </ComponentBoundary>
      <styleds.trendChartGroupContent>
        <SymbolChart symbol='GC-1'/>
        <SymbolChart symbol='DX-1'/>
        <SymbolChart symbol='S-1'/>
        <SymbolChart symbol='RTY-1'/>
        <SymbolChart symbol='BTCUSDT'/>
      </styleds.trendChartGroupContent>
    </div>);
});
const Line960StateCard = memo(function Line960StateCard() {
    const state = useSnapshot(goodwayStore);
    const diff = state.txf - state.ma960;
    const result = diff > 0 ? '線上' : diff < 0 ? '線下' : '無';
    const fill = diff > 0 ? '#c4403d' : diff < 0 ? '#74a24a' : '#777777';
    return (<styleds.maValueContainer>
      <div>960均線狀態</div>
      <styleds.maValueItem fill={fill}>{result}</styleds.maValueItem>
    </styleds.maValueContainer>);
});
const ValueCard = memo(function ValueCard(props) {
    const value = props.value;
    const reverse = props.reverse ?? false;
    const aaa = reverse ? -value : value;
    const isPositive = aaa > 0;
    return (<styleds.cardContainer positive={isPositive}>
      <styleds.cardTitle>{props.children}</styleds.cardTitle>
      <styleds.cardValue positive={isPositive}>{props.value}</styleds.cardValue>
    </styleds.cardContainer>);
});
/** 其他商品走勢內容 */
const SymbolChart = memo(function SymbolChart(props) {
    return (<styleds.trendChartContainer>
        <TitleSymbolQuote.Default symbol={props.symbol}/>
        <styleds.trendChart>
          <ComponentBoundary filepath='<IntradayTrendChart />'>
            <IntradayTrendChart symbol={props.symbol} ticksSize={13} ticksHeight={18} priceTicksMargin={0} priceTicksSize={13} hidePriceLabel={priceLabel} hideTimeLabel={timeLabel} tickGap={props.tickGap} intraday={true}/>
          </ComponentBoundary>
        </styleds.trendChart>
      </styleds.trendChartContainer>);
});
const PageSwitch = memo(function PageSwitch(props) {
    const state = useSnapshot(goodwayStore).pageState;
    const selectedPage = state === props.keys;
    return (<styleds.switchButton selected={selectedPage} onClick={() => (goodwayStore.pageState = props.keys)}>
        <AppLink2 href={props.url} css={css `
            ${fill_horizontal_all_center};
            color: ${selectedPage ? '#ffffff' : '#888888'};
          `}>
          {props.children}
          {selectedPage}
        </AppLink2>
      </styleds.switchButton>);
});
const styleds = {
    //走勢圖元件
    trendChartContainer: styled.div `
    width: 100%;
    background-color: #1f1f1f;
    border-radius: 4px;
  `,
    trendChart: styled.div `
    width: 100%;
    height: ${chartHight}px;
    padding: 2px;
  `,
    //均線狀態元件
    maValueContainer: styled.div `
    ${fill_horizontal_all_center};
    height: 48px;
    background-color: #1f1f1f;
    border-radius: 4px;
    gap: 24px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      background-color: #2f2f2f;
      transition: 0.3s;
    }
  `,
    maValueItem: styled.div `
    ${fill_horizontal_all_center};
    height: 26px;
    width: 124px;
    background-color: ${props => props.fill};
    border-radius: 4px;
  `,
    //坦克、泡灰、游擊隊數值卡元件
    cardContainer: styled.div `
    ${fill_vertical_all_center};
    width: 96px;
    height: 116px;
    background: ${props => (props.positive === true ? '#452121;' : '#243620')};
    border-radius: 12px;
    gap: 16px;
    font-size: 14px;
    border: 1px solid transparent;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      margin-bottom: 4px;
      border: 1px solid #898989;
      font-size: 14.8px;
      transition: 0.3s;
    }
  `,
    cardTitle: styled.div `
    ${fill_horizontal_all_center};
    height: 32px;
  `,
    cardValue: styled.div `
    ${fill_horizontal_all_center};
    width: 64px;
    height: 32px;
    background: ${props => (props.positive === true ? '#bf3b39' : '#66a43a')};
    border-radius: 6px;
  `,
    //商品元件
    symbolInfoContainer: styled.div `
    width: 100%;
    height: 88px;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-rows: 33% 33% 33%;
    font-size: 14px;
    padding: 0 16px;
    > p {
      ${fill_horizontal_cross_center};
      margin: 0;
    }
  `,
    //SidePane2元件
    calueCardContainer: styled.div `
    ${fill_horizontal_all_center};
    height: 116px;
    gap: 12px;
    padding: 4px;
  `,
    //多圖表群組
    trendChartGroupContent: styled.div `
    ${scrollbar2Css};
    height: calc(100% - 272px);
    gap: 8px;
  `,
    symbolSwitchContent: styled.div `
    ${fill_horizontal_all_center};
    height: 32px;
    gap: 4px;
  `,
    symbolButton: styled.div `
    ${fill_horizontal_all_center};
    height: 26px;
    border-radius: 4px;
    color: #ffffff;
    background-color: ${props => (props.selected ? '#353535' : '#232323')};
    border: 1px solid ${props => (props.selected ? '#aaaaaa' : '#353535')};
    cursor: pointer;
    &:hover {
      background-color: ${props => (props.selected ? '#3a3a3a' : '#2a2a2a')};
    }
  `,
    //切換頁面元件
    switchButton: styled.div `
    ${fill_horizontal_all_center};
    width: 100%;
    height: 32px;
    background-color: #444444;
    border-radius: 4px;
    cursor: pointer;
    color: #ffffff;
    border: 1px solid ${props => (props.selected ? '#ffffff' : '#222222')};
    &:hover {
      background-color: #545454;
    }
  `,
};
