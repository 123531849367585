import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ColorType } from 'lightweight-charts';
import { memo } from 'react';
import { scrollbar2Css } from '~/css/scrollbarCss';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_cross_center } from '~/modules/AppLayout/FlexGridCss';
import { fr_instrument } from '~/modules/SDK/FrInstrument/FrInstrument';
import { fr_liteChart } from '~/pages/heineken_template/_fr/fr_liteChart';
import dayAPI from '~/utils/dayAPI';
//---------------------------------------------
fr_liteChart.themes.dark.candlestickSeries = {
    upColor: '#ff4400',
    downColor: '#00aa00',
    wickUpColor: '#ffffff',
    wickDownColor: '#ffffff',
    borderVisible: false,
};
fr_liteChart.themes.dark.chartOptions = {
    layout: {
        background: { type: ColorType.Solid, color: '#1f1f1f' },
        textColor: '#ffffff',
    },
    timeScale: {
        borderColor: '#ffffff',
    },
    rightPriceScale: {
        borderColor: '#ffffff',
    },
    grid: {
        horzLines: { color: '#666666' },
        vertLines: { color: '#666666' },
    },
};
//---------------------------------------------
const symbols = ['2323', '2388', 'DX-1', 'GC-1', 'BTCUSDT', 'NQ-1', 'YM-1', 'S-1', 'RTY-1'];
export const StockMonitors = memo(function StockMonitors() {
    const { isPhone } = useMedia();
    return (<div css={css `
        width: 100%;
        ${scrollbar2Css};
      `}>
      <styleds.chartContainer isPhone={isPhone}>
        {symbols.map(symbol => {
            const symbolName = fr_instrument.getName(symbol);
            return (<styleds.chartContent key={symbol} isPhone={isPhone}>
              <styleds.chartTitle>
                {symbolName}
                {symbol}
              </styleds.chartTitle>
              <fr_liteChart.Chart symbol={symbol} interval={'D'} from={dayAPI().subtract(240, 'days')}>
                <fr_liteChart.Kbars />
              </fr_liteChart.Chart>
            </styleds.chartContent>);
        })}
      </styleds.chartContainer>
    </div>);
});
const styleds = {
    chartContainer: styled.div `
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: repeat(${props => (!props.isPhone ? 3 : 9)}, 1fr);
    grid-template-columns: repeat(${props => (!props.isPhone ? 3 : 1)}, 1fr);
    gap: 8px;
    padding: 4px;
  `,
    chartContent: styled.div `
    display: grid;
    grid-template-rows: 28px 1fr;
    width: 100%;
    height: ${props => (!props.isPhone ? '100%' : '200px')};
    background-color: #1f1f1f;
    border-radius: 4px;
    padding: 4px;
    gap: 4px;
  `,
    chartTitle: styled.div `
    ${fill_horizontal_cross_center};
    background-color: #2f2f2f;
    border-radius: 4px;
    padding: 0 4px;
  `,
};
